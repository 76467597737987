import React from 'react'
import Layout from 'components/Layout'
import PropTypes from 'prop-types'
import Content from 'components/Content'
import { graphql } from 'gatsby'

function DashboardIndex({ data, location }) {
	const { title } = data.site.siteMetadata
	return (
		<Layout id="Top" location={location} title={title}>
			<Content />
		</Layout>
	)
}

DashboardIndex.propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object,
}

// [TOLI, 20191019]
export const pageQuery = graphql`
{
	site {
		siteMetadata {
			title
		}
	}
	IntellectLogo: file(relativePath: {eq: "INTELLECT_LOGO.jpg"}) {
		childImageSharp {
			fluid(maxWidth: 1000) {
				base64
				tracedSVG
				aspectRatio
				src
				srcSet
				srcWebp
				srcSetWebp
				sizes
				originalImg
				originalName
			}
		}
	}
}
`
export default DashboardIndex
