import React, { memo } from 'react'

import imgLogoINTELLECT from "../../../static/images/INTELLECT_LOGO.jpg"
import imgCVWordCloud from "../../../static/images/Toli-CV_WordCloud-NEW.jpg"

function ContentGraphic() {
	return (
		<div>
			<span className="image main">
				<img src={imgLogoINTELLECT} alt="Logo_INTELLECT" width="35%"/>
				<img src={imgCVWordCloud} alt="CV_WordCloud" width="58%"/>
			</span>
		</div>
	)
}

export default memo(ContentGraphic)
