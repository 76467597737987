import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import ContentGraphic from './ContentGraphic'
import WelcomeHeader from '../../StaticQueries/WelcomeHeader'

//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'
//irvingTheme.baseFontSize = '24px'

const typography = new Typography(irvingTheme)


const styles = theme => ({
	paper: {
		maxWidth: 1700,
		margin: 'auto',
		overflow: 'hidden',
		[theme.breakpoints.up('sm')]: {
			minWidth: 600,
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: 936,
		},
	},
	searchBar: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},
	block: {
		display: 'block',
	},
	addUser: {
		marginRight: theme.spacing.unit,
	},
	contentWrapper: {
		margin: '40px 16px',
		// height: 368,
	},
	container: {
		padding: '48px 36px 48px',
	},
})

function Content({ classes }) {
  return (
    <>
      <div className={classes.container}>
        <Paper className={classes.paper}>

          <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            {/* <h1 align="center" style={{ color: 'green' }}>-= W°E°L°C°O°M°E°! =-</h1> */}
			<WelcomeHeader/>
          </AppBar>

          <div className={classes.contentWrapper} style={{fontSize: '160%', color: 'blue'}}>
			<ContentGraphic />

            {/* <Typography color="textSecondary" align="justify"> */}
			<typography color="textSecondary" align="justify">
				'<b><span style={{color: '#B8860B'}}>INTELLECT Computer Systems Inc.</span></b>' is 
				one of the two software development companies that are hosted by the 
				'<b><span style={{color: 'green'}}>Great Horizons</span></b>' domain together with 
				a music production entity that has an extensive portfolio of original composition 
				works almost all of which are awaiting for final mastering and distribution/publishing.
			</typography>
			{/* </Typography> */}
          </div>
        </Paper>
      </div>
    </>
  )
}

Content.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Content)
